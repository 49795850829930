
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      isMounted: false,
      isMenuOpen: false,
      headerActiveTab: null,
      keyword: null,
      menuPrimaryItems: {
        Home: '/',
        Liederen: '/liederen',
        'Programma\'s': {
          expand: true,
          children: {
            'Nederland Zingt': '/programmas/nederland-zingt',
            'Nederland Zingt op Zondag': '/programmas/nederland-zingt-op-zondag',
            'Nederland Zingt dichtbij': 'https://www.eo.nl/programmas/nederland-zingt-dichtbij',
            'Dit is mijn lied podcast': '/programmas/dit-is-mijn-lied',
          },
        },
        Activiteiten: '/activiteiten',
        Contact: '/contact',
      },
      menuSecondaryItems: {
        'Word vriend': this.$config.baseUrlMembership,
        'Over Nederland Zingt': '/over',
        Contact: '/contact',
      },
      menuSocialItems: {
        youtube: 'https://www.youtube.com/user/NederlandZingt',
        facebook: 'https://nl-nl.facebook.com/NederlandZingt',
        instagram: 'https://www.instagram.com/eo_nederlandzingt/',
        spotify: 'https://open.spotify.com/artist/1nuQi198AGHpHheLcS9OOC',
      },
      footerShortcuts: {
        'Ga naar...': {
          Home: '/',
          Liederen: '/liederen',
          'Uitzendingen': '/programmas/nederland-zingt',
          Activiteiten: '/activiteiten',
          'Over Nederland Zingt': '/over',
          'Word vriend': this.$config.baseUrlMembership,
          Contact: '/contact',
        },
      },
      footerSocials: {
        'Volg ons op...': {
          youtube: {
            name: 'Youtube',
            path: 'https://www.youtube.com/user/NederlandZingt',
          },
          facebook: {
            name: 'Facebook',
            path: 'https://nl-nl.facebook.com/NederlandZingt',
          },
          instagram: {
            name: 'Instagram',
            path: 'https://www.instagram.com/eo_nederlandzingt/',
          },
          spotify: {
            name: 'Spotify',
            path: 'https://open.spotify.com/artist/1nuQi198AGHpHheLcS9OOC',
          },
        },
      },
    }
  },
  head: {
    // @todo: move this preload to ccm module itself
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.eo.nl/sites/EO/eo.nl/npo-lowerbar_v3.1.10.css',
        crossorigin: '',
      },
    ],
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  beforeUpdate() {
    // For iOS app, use Apple's cookie popup to accept or decline the NPO cookie popup
    if (window && navigator?.userAgent?.indexOf('GoNativeIOS') > -1 && !this.$store.state.cookieConsent.necessary) {
      window.gonative?.ios.attconsent?.status({
        callback: ({ granted }) => {
          if (granted) {
            window.ccm?.cookieMessage?.acceptCCM('analytics', 'necessary', 'recommendations', 'social')
          } else {
            window.ccm?.cookieMessage?.declineCCM()
          }
        },
      })
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onNavigate () {
      this.isMenuOpen = false
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
