export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.BITBUCKET_TAG || `v0.0.0-rc.${process.env.BITBUCKET_BUILD_NUMBER}`,
  BASE_URL: (process.env.BITBUCKET_PR_ID && `https://pr-${process.env.BITBUCKET_PR_ID}-nederland-zingt.feat.eo.nl`) || process.env.BASE_URL || 'https://nederlandzingt.eo.nl',
  BASE_URL_CANONICAL: process.env.BASE_URL_CANONICAL || 'https://nederlandzingt.eo.nl',
  EO_API_HOST: process.env.EO_API_HOST || 'https://prod-api.eo.nl',
  NEW_EO_API_HOST: process.env.NEW_EO_API_HOST || 'https://api.eo.nl',
  EO_API_VERSION: 'v1',
  PREPR_ENVIRONMENT_DOMAIN: process.env.PREPR_ENVIRONMENT_DOMAIN || 'nederlandzingt-eo',
  PREPR_ENVIRONMENT_DOMAIN_BIJBELOPEN: process.env.PREPR_ENVIRONMENT_DOMAIN_BIJBELOPEN || 'bijbelopen-eo',
  PREPR_COMMUNITY_ID: process.env.PREPR_COMMUNITY_ID || '92728d7c-f683-4c9f-9a43-e1cfcee1bb11',
  PLAYER_KEY: process.env.PLAYER_KEY,
  PLAYER_ANALYTICS_KEY: process.env.PLAYER_ANALYTICS_KEY,
}