
export default {
  data() {
    return {
      // Layout data
      activeTab: null,
      // Campaign data
      articles: [],
      callToAction: null,
      changedOn: null,
      description: [],
      environmentId: null,
      faq: [],
      hero: null,
      heroVideo: null,
      heroVideoStill: null,
      id: null,
      intro: null,
      lineUp: [],
      publishOn: null,
      participate: [],
      seo: null,
      slug: null,
      social: null,
      terms: [],
      title: null,
      topic: null,
    }
  },
  async fetch() {
    // Get campaign for this slug
    const { data: { items: campaigns = [] } = {} } = await this.$api.campaigns.getOneBySlug(
      this.$route.params.slug,
      {
        domain: this.$config.PREPR_ENVIRONMENT_DOMAIN_VISIE,
      },
    )

    // Check publication if exists
    if (!campaigns || !campaigns.length) {
      return false
    }

    // Save campaig data
    this.callToAction = campaigns[0].callToAction
    this.changedOn = campaigns[0].changedOn
    this.description = campaigns[0].description
    this.environmentId = campaigns[0].environmentId
    this.faq = campaigns[0].faq
    this.hero = campaigns[0].hero
    this.heroVideo = campaigns[0].heroVideo
    this.heroVideoStill = campaigns[0].heroVideoStill
    this.id = campaigns[0].id
    this.intro = campaigns[0].intro
    this.lineUp = campaigns[0].lineUp
    this.publishOn = campaigns[0].publishOn
    this.participate = campaigns[0].participate
    this.seo = campaigns[0].seo
    this.slug = campaigns[0].slug
    this.social = campaigns[0].social
    this.terms = campaigns[0].terms
    this.title = campaigns[0].title
    this.topic = campaigns[0].topic

    // Get articles linked to this campaign
    const { data: { items: articles = [] } = {} } = await this.$api.articles.getAll({
      limit: 3,
      campaign: this.id,
    })
    this.articles = articles

    // Set breadcrumbs for the page
    this.$store.dispatch('breadcrumbs/set', {
      path: `/actie/${this.slug}`,
      eoRoot: false,
      floating: true,
      width: 10,
      items: [
        {
          to: '/',
          label: 'Nederland Zingt',
        },
        {
          label: this.title,
        },
      ],
    })
  },
  computed: {
    breadcrumbs() {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth() {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating() {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot() {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash() {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  methods: {
    errorCaptured(error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
