
export default {
  props: {
    color: {
      type: String,
      default: 'grey-800',
    },
    textColor: {
      type: String,
      default: 'white',
    },
  },
  data () {
    return {
      isMounted: false,
      isMenuOpen: false,
      headerActiveTab: null,
      keyword: null,
      menuSecondaryItems: {
        'Word vriend': this.$config.baseUrlMembership,
        'Over Nederland Zingt': '/over',
        Contact: '/contact',
      },
      menuSocialItems: {
        youtube: 'https://www.youtube.com/user/NederlandZingt',
        facebook: 'https://nl-nl.facebook.com/NederlandZingt',
        instagram: 'https://www.instagram.com/eo_nederlandzingt/',
        spotify: 'https://open.spotify.com/artist/1nuQi198AGHpHheLcS9OOC',
      },
      footerShortcuts: {
        'Ga naar...': {
          Home: '/',
          Liederen: '/liederen',
          'Tv-programma': '/programmas/nederland-zingt',
          Activiteiten: '/activiteiten',
          'Over Nederland Zingt': '/over',
          'Word vriend': this.$config.baseUrlMembership,
          Contact: '/contact',
        },
      },
      footerSocials: {
        'Volg ons op...': {
          youtube: {
            name: 'Youtube',
            path: 'https://www.youtube.com/user/NederlandZingt',
          },
          facebook: {
            name: 'Facebook',
            path: 'https://nl-nl.facebook.com/NederlandZingt',
          },
          instagram: {
            name: 'Instagram',
            path: 'https://www.instagram.com/eo_nederlandzingt/',
          },
          spotify: {
            name: 'Spotify',
            path: 'https://open.spotify.com/artist/1nuQi198AGHpHheLcS9OOC',
          },
        },
      },
    }
  },
  head: {
    // @todo: move this preload to ccm module itself
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.eo.nl/sites/EO/eo.nl/npo-lowerbar_v3.1.10.css',
        crossorigin: '',
      },
    ],
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  watch: {
    '$route' () {
      // Keep track of amount music pages viewed to show OneSignal pop-up
      const numViewsTrigger = 3
      if (window.OneSignal && typeof localStorage !== 'undefined') {
        let viewsCount = parseInt(localStorage.getItem('viewsCount'), 10)
        if (!isNaN(viewsCount)) {
          viewsCount += 1
        } else {
          viewsCount = 0
        }
        localStorage.setItem('viewsCount', viewsCount)

        if (viewsCount == numViewsTrigger) {
          window.OneSignal.push(() => {
            window.OneSignal.showCategorySlidedown()
          })
        }
      }
    },
  },
  beforeUpdate() {
    // For iOS app, use Apple's cookie popup to accept or decline the NPO cookie popup
    if (window && navigator?.userAgent?.indexOf('GoNativeIOS') > -1 && !this.$store.state.cookieConsent.necessary) {
      window.gonative?.ios.attconsent?.status({
        callback: ({ granted }) => {
          if (granted) {
            window.ccm?.cookieMessage?.acceptCCM('analytics', 'necessary', 'recommendations', 'social')
          } else {
            window.ccm?.cookieMessage?.declineCCM()
          }
        },
      })
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onSearch (keyword) {
      this.$router.push({
        path: '/liederen/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
    navigateBack () {
      if (this.$route.path === '/liederen') {
        this.$router.push('/')
      } else if (this.$route.path === '/liederen/zoeken') {
        this.$router.push('/liederen')
      } else if (window.history.length <= 1) {
        // No previous page in history
        if (this.$route.path.includes('/liederen/lied')) {
          this.$router.push('/liederen')
        } else if (this.$route.path.includes('/liederen/afspeellijst')) {
          this.$router.push('/liederen')
        } else {
          this.$router.push('/')
        }
      } else {
        // Previous page in history
        this.$router.go(-1)
      }
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
