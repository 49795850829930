/* eslint-disable no-unused-vars */
import { pianoAnalytics } from 'piano-analytics-js'
import loglevel from 'loglevel'

let { debug, namespace, sandboxSiteId, siteId, project, contextId } = JSON.parse(
  '{"debug":false,"namespace":"piano-analytics","logLevel":"warn","siteId":"630014","sandboxSiteId":"630017","project":"nederlandzingt","contextId":"VPWON_1257897"}',
)
const log = loglevel.getLogger('piano-plugin')

function sendEvent(to, from, project) {
  pianoAnalytics.sendEvent('page.display', {
    page: to.path.toLowerCase(),
    previous_url: from.path.toLowerCase(),
    c_nmo_02: to.path === '/' ? 1 : 0,
    platform: 'site',
    broadcaster: 'eo',
    contextId,
    programma: project,
    page_chapter1: project,
  })
}

export default ({ $config: { appEnv }, app: { router }, store }, inject) => {
  /**
   * Get the "plus site" -art for page counter chapter1 (former ati_chapter1)
   *
   * Project depends on what the EO data department needs.
   *
   * PROGRAMS
   * All subpages of /programmas, chapter1 is the program name
   * URL /programmas/programmanaam (or a subpage) then
   * chapter1 ==> 'programmanaam'.
   */
  const getProject = (to) => {
    const path = to ? to.path : location.pathname
    const elements = path.toLowerCase().split('/')
    log.debug(`[${namespace}] create projectname with`, { path, elements, project })

    let projectName = store.getters['pianoAnalytics/project'] || project
    projectName = projectName?.replace(/-/g, '') // For backwardcompatablity remove dashes. het-familiediner -> hetfamiliediner
    return projectName
  }

  // After each route change send a new page_display event to piano analytics
  router.afterEach((to, from) => {
    if (to.path.toLowerCase() === from.path.toLowerCase() && to.query?.v !== from.query?.v) {
      // Don't send page display event when only the params in url change, because this does not count as another page display event.
      return
    }

    // Set pianoAnalytics configurations
    siteId = store.getters['pianoAnalytics/siteId'] || siteId
    sandboxSiteId = store.getters['pianoAnalytics/sandboxSiteId'] || sandboxSiteId
    if (appEnv !== 'production' && !debug) {
      log.warn(
        `[${namespace}] Forced siteId to "Sandbox" for ${appEnv}-environment (non-production)`,
      )
      siteId = sandboxSiteId
    }
    if (!siteId) {
      log.error(`[${namespace}] Missing Site ID`)
      return
    }
    pianoAnalytics.setConfigurations({
      site: siteId,
      collectDomain: 'https://atconnect.npo.nl',
    })
    const project = getProject(to)

    // tag.min.js for MNO DAM is not loaded, load it dynamically first before sending pa events
    if (typeof _NMODAMConfig === 'undefined') {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://hub.npo-data.nl/tags/tag.min.js'
      script.async = true
      document.head.appendChild(script)
      script.onload = function () {
        sendEvent(to, from, project)
      }
    } else {
      // Tag script was already loaded so send the event
      sendEvent(to, from, project)
    }
  })

  // Make pianoAnalytics available via the nuxt context with this.$pa
  inject('pa', pianoAnalytics)
}
