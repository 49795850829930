export const EoLikeButton = () => import('../../node_modules/@eo/nuxt-like/lib/components/EoLikeButton.vue' /* webpackChunkName: "components/eo-like-button" */).then(c => wrapFunctional(c.default || c))
export const LayoutDefault = () => import('../../components/LayoutDefault.vue' /* webpackChunkName: "components/layout-default" */).then(c => wrapFunctional(c.default || c))
export const LayoutMusic = () => import('../../components/LayoutMusic.vue' /* webpackChunkName: "components/layout-music" */).then(c => wrapFunctional(c.default || c))
export const ListItemSong = () => import('../../components/ListItemSong.vue' /* webpackChunkName: "components/list-item-song" */).then(c => wrapFunctional(c.default || c))
export const NewsletterExample = () => import('../../components/NewsletterExample.vue' /* webpackChunkName: "components/newsletter-example" */).then(c => wrapFunctional(c.default || c))
export const PreprPage = () => import('../../components/PreprPage.vue' /* webpackChunkName: "components/prepr-page" */).then(c => wrapFunctional(c.default || c))
export const MusicGridPlaylists = () => import('../../components/MusicGrid/Playlists.vue' /* webpackChunkName: "components/music-grid-playlists" */).then(c => wrapFunctional(c.default || c))
export const MusicGridSongs = () => import('../../components/MusicGrid/Songs.vue' /* webpackChunkName: "components/music-grid-songs" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCardArticle = () => import('../../components/NederlandZingtCard/Article.vue' /* webpackChunkName: "components/nederland-zingt-card-article" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCardDefault = () => import('../../components/NederlandZingtCard/Default.vue' /* webpackChunkName: "components/nederland-zingt-card-default" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCardEpisode = () => import('../../components/NederlandZingtCard/Episode.vue' /* webpackChunkName: "components/nederland-zingt-card-episode" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCardPlaylist = () => import('../../components/NederlandZingtCard/Playlist.vue' /* webpackChunkName: "components/nederland-zingt-card-playlist" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCardPlaylistItem = () => import('../../components/NederlandZingtCard/PlaylistItem.vue' /* webpackChunkName: "components/nederland-zingt-card-playlist-item" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCardProgram = () => import('../../components/NederlandZingtCard/Program.vue' /* webpackChunkName: "components/nederland-zingt-card-program" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCardSong = () => import('../../components/NederlandZingtCard/Song.vue' /* webpackChunkName: "components/nederland-zingt-card-song" */).then(c => wrapFunctional(c.default || c))
export const NederlandZingtCard = () => import('../../components/NederlandZingtCard/index.vue' /* webpackChunkName: "components/nederland-zingt-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
