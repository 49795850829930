const axios = require('axios')
const { constants } = require('../config')

axios.defaults.baseURL = `${constants.NEW_EO_API_HOST}`

export default async function ({ redirect, route }) {
  const { fullPath, path, query } = route
  if (path !== '/publication' && path !== '/publication/') {
    return
  }

  const errorPage = `/error${fullPath}`

  const {
    id,
    'model-id': modelId,
    'environment-slug': environmentSlug,
    story,
  } = query

  let { slug } = query

  const articleRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug:  pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/articles/${id}`)
      slug = pubSlug
    }

    if (environmentSlug === 'bijbelopen-eo') {
      return redirect(301, `/bijbelopen/${slug}`)
    }

    return redirect(301, `/artikel/${slug}`)
  }

  const campaignRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/campaign/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/actie/${slug}`)
  }


  const eventRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug:  pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/events/${id}`, {
        params: {
          aggregate: 0,
        },
      })
      slug = pubSlug
    }
    return redirect(301, `/activiteit/${slug}`)
  }

  const personRoute = async () => {
    if (!id && !slug) {
      return redirect(errorPage)
    }

    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/persons/${id}`)
      slug = pubSlug
    }

    return redirect(301, `/artiest/${slug}`)
  }

  const podcastRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug:  pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/podcasts/${id}`)
      slug = pubSlug
    }
    return redirect(301, `/programmas/${slug}`)
  }

  const programRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug: pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/programs/${id}`, {
        params: {
          aggregate: 0,
        },
      })
      slug = pubSlug
    }
    return redirect(301, `/programmas/${slug}`)
  }

  const pageRoute = async () => {
    if (!slug) {
      const {
        data: {
          item: {
            slug:  pubSlug = {},
          } = {},
        } = {},
      } = await axios.get(`/pages/${id}`)
      slug = pubSlug
    }

    if (environmentSlug === 'bijbelopen-eo') {
      return redirect(301, `/bijbelopen/${slug}`)
    }

    return redirect(301, `/${slug}`)
  }

  if (modelId) {
    switch (modelId) {
      case '9ad0aecf-f7f9-431c-9193-52bc5bc22971': // article
        return await articleRoute()
      case 'd297be78-bf58-4270-96a0-b9aec336f952': // campaign
        return await campaignRoute()
      case '5abf9393-c127-488a-ac47-72917ec1c701': // event
        return await eventRoute()
      case '3e97b652-7f9f-4b47-91ef-5f63b448a983': // person
        return await personRoute()
      case 'a45ca4c5-4010-4608-8174-94354e618901': // podcast
        return await podcastRoute()
      case 'fa9af868-5af3-49db-8b55-ff1155b2a53b': // program
        return await programRoute()
      case 'eceb6d16-a6e8-439c-88d8-286c97a580ff': // page
        return await pageRoute()
      case '07d69113-d504-4c04-9f03-f2338985c96a': // tag
      case 'e207ea24-66a3-469e-b257-91b400bbce50': // (Hoofd)tag
        return redirect(301, `/alles-over/${slug}`)
    }
  }

  if (story) {
    return redirect(301, `/rubriek/${story}`)
  }

  if (slug) {
    return redirect(301, slug)
  }

  redirect(errorPage)
}
