export default {
  data () {
    return {
      head: {},
    }
  },
  computed: {
    heroImage () {
      if (!this.hero || this.hero === '') {
        return {
          sd: null,
          hd: null,
        }
      }

      return {
        sd: this.hero.replace('{format}', 'h_50'),
        hd: `${this.hero.replace('{format}', 'w_375')} 375w,
            ${this.hero.replace('{format}', 'w_480')} 480w,
            ${this.hero.replace('{format}', 'w_600')} 600w,
            ${this.hero.replace('{format}', 'w_900')} 900w,
            ${this.hero.replace('{format}', 'w_1200')} 1200w,
            ${this.hero.replace('{format}', 'w_1920')} 1920w`,
      }
    },
  },
  created () {
    if (this.heroImage && (this.heroImage.sd || this.heroImage.hd)) {
      const preloadHero = {
        rel: 'preload',
        as: 'image',
        href: this.heroImage.sd,
        imagesrcset: this.heroImage.hd,
      }
      if (this.head.link) {
        this.head.link.push(preloadHero)
      } else {
        this.head.link = [preloadHero]
      }
    }
  },
}
