export default {
  computed: {
    dateFormat () {
      return {
        past: (date) => {
          /**
           * Format date to human readable
           * @param {string} date - A string date or numeric unix timestamp
           * @returns {string} date - readable time from now or simple date when long ago
           */
          const then = isNaN(date) ? this.$moment(date) : this.$moment.unix(date)
          const now = this.$moment()
          const daysInPast = now.diff(then, 'days', true)
          return daysInPast > 3 ? then.format('D MMMM YYYY') : then.fromNow()
        },
        dayAndDate: (date) => {
          /**
           * Format date from unix timestamp to day of the week readable date
           * @param {string} date - A string date or numeric unix timestamp
           * @returns {string} date - Readable time with day of the week prefixed
           */
          const moment = isNaN(date) ? this.$moment(date) : this.$moment.unix(date)
          return moment.format('dddd D MMMM YYYY')
        },
        dayDateAndTime: (date) => {
          /**
           * Format date from unix timestamp to readable day of the week and time
           * @param {string} date - A string with date information from the Hcms
           * @returns {string} date - Readable time with day of the week prefixed
           */
          const moment = isNaN(date) ? this.$moment(date) : this.$moment.unix(date)
          return moment.format('dddd DD MMMM [om] H:mm')
        },
        isoDuration: (duration) => {
          /**
           * Format duration to ISO_8601 temporal duration
           * @param {string} duration - A string with duration in h:m:s format
           * @returns {string} duration - Duration written in ISO_8601
           */
          const moment = this.$moment.duration(duration)
          return moment.toISOString()
        },
        time: (date) => {
          /**
           * Format date from unix timestamp to readable day of the week and time
           * @param {string} date - A string with date information from the Hcms
           * @returns {string} date - Readable time with day of the week prefixed
           */
          const moment = isNaN(date) ? this.$moment(date) : this.$moment.unix(date)
          return moment.format('H:mm')
        },
        daysInPast: (date) => {
          /**
           * Format date from unix timestamp to amount of days in past from now
           * @param {string} date - A string with date information from the Hcms
           * @returns {int} days - Readable amount of days in past
           */
          this.$moment.relativeTimeThreshold('ss', 0)
          const now = this.$moment()
          const start = isNaN(date) ? this.$moment(date) : this.$moment.unix(date)
          return Math.abs(start.diff(now, 'days', true))
        },
      }
    },
  },
}
