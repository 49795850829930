
export default {
  data() {
    return {
      showIOS: true,
      showAndroid: true,
    }
  },
  mounted() {
    // Don't show unneeded option if user comes here from the old app
    if (window && navigator?.userAgent?.indexOf('GoNativeIOS') > -1) {
      this.showAndroid = false
    } else if (window && navigator?.userAgent?.indexOf('GoNativeAndroid') > -1) {
      this.showIOS = false
    }
    },
  }
