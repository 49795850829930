
export default {
  data () {
    return {
      isMounted: false,
      isMenuOpen: false,
      currentIndex: -1,
      todayIndex: -1,
      allArticles: [],
    }
  },
  async fetch () {
    const { data: { items: allArticles } } = await this.$api.articles.getAll({
      domain: this.$config.preprEnvironmentDomainBijbelopen,
      limit: 365,
    })
    this.allArticles = allArticles
    this.currentIndex = Math.max(0, allArticles.findIndex(article => article.slug == this.$route.params.slug))
    this.todayIndex = Math.max(0, allArticles.findIndex(article => new Date(article.publishOn).toLocaleDateString() === new Date().toLocaleDateString()))
  },
  head: {
    // @todo: move this preload to ccm module itself
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.eo.nl/sites/EO/eo.nl/npo-lowerbar_v3.1.10.css',
        crossorigin: '',
      },
    ],
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  watch: {
    '$route' () {
      if (this.allArticles) {
        this.currentIndex = Math.max(0, this.allArticles.findIndex(article => article.slug == this.$route.params.slug))
      }
    },
  },
  beforeUpdate() {
    // For iOS app, use Apple's cookie popup to accept or decline the NPO cookie popup
    if (window && navigator?.userAgent?.indexOf('GoNativeIOS') > -1 && !this.$store.state.cookieConsent.necessary) {
      window.gonative?.ios.attconsent?.status({
        callback: ({ granted }) => {
          if (granted) {
            window.ccm?.cookieMessage?.acceptCCM('analytics', 'necessary', 'recommendations', 'social')
          } else {
            window.ccm?.cookieMessage?.declineCCM()
          }
        },
      })
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    navigateToDay (delta) {
      const newIndex = this.currentIndex + delta
      if (newIndex <= this.allArticles.length - 1 && newIndex >= 0) {
        this.$router.push(`/bijbelopen/${this.allArticles[newIndex]?.slug}`)
        this.currentIndex = newIndex
      }
    },
    errorCaptured (error, vm, info) {
      this.$log.error({
        ...error,
        ...info,
      })
    },
  },
}
