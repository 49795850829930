// Vue plugins for both server and client side
import Vue from 'vue'
import componentLibrary from '@eo/component-library'
import plugins from "@eo/component-library/src/plugins"

const options = JSON.parse(`{"isNuxt":true,"debug":false,"namespace":"component-library","baseUrl":"https://nederlandzingt.eo.nl","baseUrlApi":"https://api.eo.nl","cloudflareCdn":"https://nederlandzingt.eo.nl/cdn-image","themeFile":"~/assets/css/eo.variables.scss","preprEnvironment":"nederlandzingt-eo","npoTag":{"brand":"Nederland Zingt","brandId":630017},"EoRenderer":{"Asset":{"class":"my-2","Document":{"target":"_blank"}},"NewsletterSubscribe":{"accentColor":"secondary","color":"primary","textColor":"white","titleSize":"md"}}}`)
Vue.use(componentLibrary, options)

export default async (context, inject) => {
  // Register all plugins from library also in Nuxt global context
  Object.entries(plugins).forEach(([name]) => {
    inject(name, Vue.prototype['$' + name])
  })
}