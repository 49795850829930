export default {
  data () {
    return {
      isNotificationsAllowed: null,
      notificationSubscriptions: [],
    }
  },
  activated () {
    window.OneSignal.push(() => {
      this.isNotificationsAllowed = window.OneSignal.isPushNotificationsSupported()
      window.OneSignal.push(["getNotificationPermission", (permission) => {
        this.isNotificationsAllowed = permission !== 'denied'
      }])
    })

    this.$_checkNotificationSubscriptions()
    window.OneSignal.push(() => {
      window.OneSignal.on('popoverClosed', () => {
        this.$_checkNotificationSubscriptions()
      })
    })
  },
  methods: {
    $_checkNotificationSubscriptions () {
      if (!this.$OneSignal) return
      window.OneSignal.push(() => {
        window.OneSignal.getTags((tags) => {
          this.notificationSubscriptions = []
          for (const [ key, value ] of Object.entries(tags)) {
            if (value === '1') {
              this.notificationSubscriptions.push(key)
            }
          }
        })
      })
    },
    showNotificationSettings () {
      // Reset, so popup also opens when user dismissed it earlier on
      localStorage?.setItem('onesignal-notification-prompt', null)
      window.OneSignal.push(() => {
        window.OneSignal.showCategorySlidedown()
      })
    },
  },
}