import { dateFormatMixin } from '~/mixins'

export default {
  mixins: [
    dateFormatMixin,
  ],
  methods: {
    renderJsonldMusicRecording(song) {
      if (!song) return {}
      return {
        '@context': 'http://schema.org',
        '@type': 'MusicRecording',
        audio: this.$config.baseUrlCanonical + this.$route.path,
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': this.$config.baseUrlCanonical + this.$route.path,
        },
        publisher: {
          '@type': 'Organization',
          name: 'EO',
          logo: {
            '@type': 'ImageObject',
            url: this.$config.baseUrlCanonical + require('~/assets/logo/evangelische-omroep.webp'),
          },
        },
        url: this.$config.baseUrlCanonical + this.$route.path,
        byArtist: song.metadata?.[ 'Uitvoerenden' ]?.split(', ').map(executedBy => {
          return {
            '@type': 'MusicGroup',
            name: executedBy,
          }
        }),
        dateCreated: song.date,
        description: song.description,
        headline: song.seo?.title,
        image: song.image?.url,
        inLanguage: song.metadata?.[ 'Taal' ],
        locationCreated: `${song.metadata?.[ 'Kerk van uitvoering' ]} in ${song.metadata?.[ 'Plaats van uitvoering' ]}`,
        name: song.title,
        duration: this.dateFormat.isoDuration(`00:${this.duration}`),
        recordingOf: {
          '@type': 'MusicComposition',
          name: song.title,
          composer: song.metadata?.[ 'Eerste componist' ]?.split(', ').map(person => {
            return {
              '@type': 'Person',
              name: person,
            }
          }),
          lyricist: song.metadata?.[ 'Schrijver(s)' ]?.split(', ').map(person => {
            return {
              '@type': 'Person',
              name: person,
            }
          }),
          lyrics: {
            '@type': 'CreativeWork',
            inLanguage: song.metadata?.[ 'Taal' ],
            text: song.lyrics,
          },
        },
      }
    },
  },
}